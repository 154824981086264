<template>
  <div>
    <component
      :is="component"
      :name="component"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingContractClosing',
  components: {
    Overview: lazyLoad('views/Onboarding/ContractClosing/Overview'),
  },
  computed: {
    ...mapGetters('user', [
      'mainStage',
      'subStage',
    ]),
    component() {
      return this.$route.params.step;
    },
  },
  methods: {
    ...mapActions('user', [
      'updateOnboarding',
      'setMainStage',
      'setSubStage',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    async submitForm() {
      try {
        await this.$refs.form.submit();
      } catch (error) {
        throw new Error(error);
      }
    },
    async next() {
      this.setLoading(true);
      const input = {
        investor: {},
        mainStage: 'contractClosing',
        subStage: 'overview',
      };
      const nextStage = await this.updateOnboarding({
        input,
      });
      this.setMainStage(nextStage.nextMainStage);
      this.setSubStage(nextStage.nextSubStage);
      this.setLoading(false);
      return this.$router.push({
        name: 'Onboarding.Overview',
      });
    },
  },
};
</script>
